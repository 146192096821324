import React from "react"
import { graphql } from "gatsby"
import {
  Section,
  SectionHeading,
  SectionLedeDiv,
} from "../../styledComponents/section"

import Layout from "../../components/layout"

const Heading = ({ level = 1, location, children }) => (
  <SectionHeading as={`h${level}`} level={level} location={location}>
    {children}
  </SectionHeading>
)

const Thankyou = ({ data, location }) => {

  return (
    <>
    <Layout displayNavBorder location={location} hideNewsletterSignUp>
      <Section>
        <Heading level={1}>
          Registration complete
        </Heading>
        <SectionLedeDiv>
        <p>Thank you for registering, Karina looks forward to welcoming you onto her workshop.</p>
        <p>If you have booked onto an online workshop please note that you will be sent a link to Zoom.</p>
        {/* <p>Please check your email you will have recieved an email with all the information, please reply to confirm that you recieved this.</p> */}
        </SectionLedeDiv>       
      </Section>
    </Layout>
    </>
  )
}

export default Thankyou

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "thank-you" }) {
      title
      content
    }
  }
`
